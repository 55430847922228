<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="姓名">
          <el-input v-model="searchData.idName" size="small" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="searchData.idCard" size="small" placeholder="请输入身份证号 "></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.gray_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'GrayAdd' })"
      >新增</el-button
    >
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.gray_list_import)"
      style="margin-bottom:20px"
      type="info"
      icon="el-icon-upload2"
      size="small"
      @click="handleUpload"
      >批量导入</el-button
    >
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
    <!-- 移库 -->
    <el-dialog title="用户所属名单修改" :visible.sync="dialogVisibleMove" width="width">
      <div class="move-content">
        <div class="item">
          <span>移出灰名单</span>
          <span>
            <el-checkbox v-model="outBlack"></el-checkbox>
          </span>
        </div>
        <div class="item">
          <span>移入黑名单</span>
          <span>
            <el-checkbox v-model="inGray"></el-checkbox>
          </span>
        </div>
      </div>
      <div class="move-submit">
        <el-button style="width:100px" type="primary" size="small" @click="saveMove">保 存</el-button>
        <el-button style="width:100px" type="" size="small" @click="dialogVisibleMove = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 记录查看 -->
    <Record ref="record" :rosterId="rosterId"></Record>
    <!-- 导入 -->
    <ImportFile ref="importFile" @getList="getList"></ImportFile>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, handleBlackMoveAPI } from './api'
import Record from './record.vue'
import ImportFile from './import-file.vue'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '用户姓名',
    prop: 'idName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['idName']}</p>
    }
  },
  {
    label: '身份证号',
    prop: 'idCardMask',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['idCardMask']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'mobileMask',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobileMask']}</p>
    }
  },

  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '系统',
    prop: 'appName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['appName']}</p>
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '操作',
    width: '180',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.gray_list_move)}
            underline={false}
            type="primary"
            onClick={() => this.handleMove(row)}
          >
            移库
          </el-link>
          <el-link
            style="margin:0 10px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.gray_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'GrayEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.gray_list_record)}
            underline={false}
            type="primary"
            onClick={() => this.getRecord(row['id'])}
          >
            记录查看
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Record, ImportFile },
  data() {
    return {
      dialogVisibleMove: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        idCard: '',
        mobile: '',
        idName: ''
      },
      moveData: {
        id: '',
        moveType: ''
      },
      outBlack: false,
      inGray: false,
      rosterId: ''
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    },
    outBlack() {
      if (this.outBlack) {
        this.inGray = false
      }
    },
    inGray() {
      if (this.inGray) {
        this.outBlack = false
      }
    }
  },
  activated() {
    // this.getList()
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = { page: this.currentPage, riskLevel: 2, pageSize: this.pageSize }
      Object.assign(params, this.searchData)
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    // 移库
    handleMove({ id }) {
      this.moveData.id = id
      this.moveData.moveType = ''
      this.outBlack = false
      this.inGray = false
      this.dialogVisibleMove = true
    },
    // 保存移库
    saveMove() {
      if (!this.outBlack && !this.inGray) {
        this.$message.error('请选择处理类型！')
        return
      }
      if (this.outBlack) {
        this.moveData.moveType = 22
      }
      if (this.inGray) {
        this.moveData.moveType = 11
      }
      handleBlackMoveAPI(this.moveData).then(() => {
        this.$message.success('移库成功')
        this.getList()
        this.dialogVisibleMove = false
      })
    },
    // 查看记录
    getRecord(rosterId) {
      this.rosterId = rosterId
      this.$refs.record.dialogVisibleRecord = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleUpload() {
      this.$refs.importFile.dialogImport = true
      this.$nextTick(() => {
        this.$refs.importFile.clear()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .move-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      flex: 1;
      margin: 0 50px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 15px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      & > span {
        font-size: 16px;
        &:first-child {
          margin-right: 100px;
        }
      }
      .el-checkbox__inner {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        &::after {
          left: 6px;
          height: 11px;
          width: 5px;
        }
      }
    }
  }
  .move-submit {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
</style>
