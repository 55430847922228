import { render, staticRenderFns } from "./record.vue?vue&type=template&id=228fd972&scoped=true"
import script from "./record.vue?vue&type=script&lang=js"
export * from "./record.vue?vue&type=script&lang=js"
import style0 from "./record.vue?vue&type=style&index=0&id=228fd972&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228fd972",
  null
  
)

export default component.exports