<template>
  <div class="wrapper">
    <el-dialog title="上传灰名单文件" :visible.sync="dialogImport" width="width">
      <div class="import-file">
        <div class="origin">
          <span class="orgin-label">数据来源选择</span>
          <el-select v-model="fromType" placeholder="请选择" size="small" clearable>
            <el-option label="内部风险用户录入" :value="1"> </el-option>
            <el-option label="外部风险用户录入" :value="2"> </el-option>
          </el-select>
        </div>
        <div class="upload">
          <div class="left">
            <div>
              <el-button size="small" type="primary" @click="selectFile">上传文档</el-button>
              <span class="tip">支持批量上传，上传格式为.xlsx格式</span>
            </div>
          </div>
          <div class="right">
            <el-button size="small" type="primary" @click="downloadTemplate">模板下载</el-button>
          </div>
        </div>
        <div class="upload1">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            action="#"
            :on-remove="handleRemove"
            :file-list="fileList"
            multiple
            accept=".xlsx"
            :http-request="uploadExcel"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
        <div class="submit">
          <div class="left"></div>
          <div class="right">
            <el-button size="small" type="" @click="dialogImport = false">取消</el-button>
            <el-button size="small" type="primary" @click="startUpload">开始上传</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downloadFile, handleUploadAPI } from './api'
export default {
  name: 'ImportFile',

  data() {
    return {
      dialogImport: false,
      url: 'https://assets-risk.test.wangshakeji.com/template/9cb291432b3cfc476d79b2b9cf007d7a.xlsx',
      fileList: [],
      file: null,
      fromType: ''
    }
  },

  mounted() {},

  methods: {
    downloadTemplate() {
      // downloadFile(this.url, '灰名单名模板.xlsx')
       let a = document.createElement('a') //创建一个<a></a>标签
      a.href = '/static/black_gray.xlsx' // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = '灰名单模板.xlsx' //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = 'none' // 障眼法藏起来a标签
      document.body.appendChild(a) // 将a标签追加到文档对象中
      a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove() // 一次性的，用完就删除a标签
    },
    uploadExcel(param) {
      this.file = param.file
    },
    startUpload() {
      if (!this.file) {
        return this.$message.error('请先选择文件')
      }
      if (!this.fromType) {
        return this.$message.error('请选择数据来源')
      }
      const data = new FormData()
      data.append('file', this.file)
      data.append('fromType', this.fromType)
      handleUploadAPI(data).then(res => {
        this.$message.success('恭喜！灰名单文件已上传成功')
        this.$emit('getList')
        this.dialogImport = false
      })
    },

    clear() {
      this.fromType = ''
      this.file = null
      this.fileList = []
      this.$refs.upload.clearFiles()
    },
    handleRemove() {
      this.clear()
    },
    selectFile() {
      this.$refs['upload'].$refs['upload-inner'].handleClick()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  .import-file {
    padding: 0 30px;
    .origin {
      margin-bottom: 10px;
      .orgin-label {
        font-size: 16px;
        margin-right: 10px;
      }
    }
    .upload {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        .tip {
          font-size: 14px;
          margin-left: 20px;
          color: #999;
        }
      }
    }
    .upload1 {
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          width: 100%;
        }
      }
    }
    .submit {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
}
</style>
