<template>
  <div class="wrapper">
    <el-dialog title="记录查看" :visible.sync="dialogVisibleRecord" width="1000px">
      <tp-table :tableData="list" :columns="columns" :showPagination="false" />
    </el-dialog>
  </div>
</template>

<script>
import { getRecordAPI } from './api'
const columns = [
  {
    label: '系统',
    prop: 'appName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['appName']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '操作类型',
    prop: 'actionType',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <el-tag v-show={row['actionType'] == 1} type="primary" size="small">
            入库
          </el-tag>
          <el-tag v-show={row['actionType'] == 2} type="warning" size="small">
            出库
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '出入库原因',
    prop: 'tagReason',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['tagReason']}</p>
    }
  },

]
export default {
  name: 'Record',
  props: {
    rosterId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisibleRecord: false,
      columns,

      list: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getRecord()
    },
    pageSize() {
      this.getRecord()
    },
    outBlack() {
      if (this.outBlack) {
        this.inGray = false
      }
    },
    inGray() {
      if (this.inGray) {
        this.outBlack = false
      }
    },
    rosterId: {
      handler(newV, oldV) {
        this.getRecord()
      }
    }
  },
  mounted() {},

  methods: {
    async getRecord() {
      this.list = await getRecordAPI(this.rosterId)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog__body {
    max-height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      background-color: #ccc;
    }
  }
}
</style>
